var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('h3', {
    staticClass: "text-primary text-center"
  }, [_vm._v("Ranking SNBP")]), _c('b-card', {
    staticClass: "bg-primary mt-3",
    staticStyle: {
      "margin-left": "35%",
      "margin-right": "35%"
    }
  }, [_c('b-card-body', {}, [_c('b-row', [_c('b-col', {
    staticClass: "text-white",
    attrs: {
      "md": "5"
    }
  }, [_c('b-media', {
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "size": "sm",
            "icon": "BarChart2Icon"
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('b-col', {
    staticClass: "text-white",
    attrs: {
      "md": "7"
    }
  }, [_c('h4', {
    staticClass: "media-heading mb-2 text-white"
  }, [_c('b', [_vm._v(" Rank Saya")])]), _c('span', [_c('b', [_vm._v(" " + _vm._s(_vm.myRanking.ranking.ke))]), _vm._v("/" + _vm._s(_vm.myRanking.ranking.dari) + " ")])])], 1)], 1)], 1), _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', [_c('h4', {
    staticClass: "text-center"
  }, [_vm._v("Top 10 Rank")]), _c('b-row', {
    staticClass: "mt-4"
  }, [_c('b-col', {
    attrs: {
      "md": "4",
      "sm": "4"
    }
  }, [_c('label', [_vm._v("Filter Jurusan")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optJurusan
    },
    on: {
      "input": function input($event) {
        return _vm.getRanking();
      }
    },
    model: {
      value: _vm.filterJurusan,
      callback: function callback($$v) {
        _vm.filterJurusan = $$v;
      },
      expression: "filterJurusan"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "sm": "2"
    }
  }), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter Berdasarkan Sekolah Anda?",
      "label-for": "filter"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    on: {
      "input": function input($event) {
        return _vm.getRanking();
      }
    },
    model: {
      value: _vm.filterSekolah,
      callback: function callback($$v) {
        _vm.filterSekolah = $$v;
      },
      expression: "filterSekolah"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "sm": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Filter Berdasarkan Provinsi Anda?",
      "label-for": "filter"
    }
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "inline": ""
    },
    on: {
      "input": function input($event) {
        return _vm.getRanking();
      }
    },
    model: {
      value: _vm.filterProvinsi,
      callback: function callback($$v) {
        _vm.filterProvinsi = $$v;
      },
      expression: "filterProvinsi"
    }
  })], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "12"
    }
  }, [_c('table', {
    staticClass: "table table-bordered responsive"
  }, [_c('tr', {
    staticClass: "bg-primary text-white",
    attrs: {
      "variant": "primary"
    }
  }, [_c('th', [_vm._v("Peringkat")]), _c('th', [_vm._v("Nama")]), _c('th', [_vm._v("Target Jurusan 1")]), _c('th', [_vm._v("Skor")]), _c('th', [_vm._v("Target Jurusan 2")]), _c('th', [_vm._v("Skor")])]), _vm._l(_vm.snbpranking, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(item.ranking.ke))]), _c('td', [_vm._v(_vm._s(item.name))]), _c('td', [_vm._v(_vm._s(item.majors[0] ? item.majors[0].major.name : "-"))]), _c('td', [_vm._v(_vm._s(item.majors[0] ? item.majors[0].nilai_akhir : "-"))]), _c('td', [_vm._v(_vm._s(item.majors[1] ? item.majors[1].major.name : "-"))]), _c('td', [_vm._v(_vm._s(item.majors[1] ? item.majors[1].nilai_akhir : "-"))])]);
  })], 2)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }