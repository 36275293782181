<template>
  <section>
    <h3 class="text-primary text-center">Ranking SNBP</h3>
    <b-card style="margin-left: 35%; margin-right: 35%" class="bg-primary mt-3">
      <b-card-body class="">
        <b-row>
          <b-col md="5" class="text-white">
            <b-media vertical-align="top">
              <template #aside>
                <feather-icon size="sm" icon="BarChart2Icon" />
              </template>
            </b-media>
          </b-col>
          <b-col md="7" class="text-white">
            <h4 class="media-heading mb-2 text-white">
              <b> Rank Saya</b>
            </h4>
            <span>
              <b> {{ myRanking.ranking.ke }}</b
              >/{{ myRanking.ranking.dari }}
            </span>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-overlay :show="loading">
      <b-card>
        <h4 class="text-center">Top 10 Rank</h4>

        <b-row class="mt-4">
          <b-col md="4" sm="4" class="">
            <label>Filter Jurusan</label>
            <v-select class="select-size-sm w-full" label="text" v-model="filterJurusan" @input="getRanking()" :clearable="true" :options="optJurusan" />
          </b-col>
          <b-col md="2" sm="2" class=""> </b-col>
          <b-col md="3" sm="3" class="">
            <b-form-group label="Filter Berdasarkan Sekolah Anda?" label-for="filter">
              <b-form-group>
                <b-form-checkbox v-model="filterSekolah" @input="getRanking()" switch inline />
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col md="3" sm="3" class="">
            <b-form-group label="Filter Berdasarkan Provinsi Anda?" label-for="filter">
              <b-form-group>
                <b-form-checkbox v-model="filterProvinsi" @input="getRanking()" switch inline />
              </b-form-group>
            </b-form-group>
          </b-col>
          <b-col md="12" class="my-1">
            <table class="table table-bordered responsive">
              <tr class="bg-primary text-white" variant="primary">
                <th>Peringkat</th>
                <th>Nama</th>
                <th>Target Jurusan 1</th>
                <th>Skor</th>
                <th>Target Jurusan 2</th>
                <th>Skor</th>
              </tr>
              <tr v-for="(item, i) in snbpranking" :key="i">
                <td>{{ item.ranking.ke }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.majors[0] ? item.majors[0].major.name : "-" }}</td>
                <td>{{ item.majors[0] ? item.majors[0].nilai_akhir : "-" }}</td>
                <td>{{ item.majors[1] ? item.majors[1].major.name : "-" }}</td>
                <td>{{ item.majors[1] ? item.majors[1].nilai_akhir : "-" }}</td>
              </tr>
            </table>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BOverlay,
  BMedia,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  BTableSimple,
  BTbody,
  BThead,
  BTfoot,
  BTr,
  BTd,
  BTh,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  // props: ["paketDataId"],
  components: {
    vSelect,
    BOverlay,
    BMedia,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    BTableSimple,
    BTbody,
    BThead,
    BTfoot,
    BTr,
    BTd,
    BTh,
    VBTooltip,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      snbpranking: [],
      myRanking: null,
      voucherData: [],
      filterRumpun: null,
      filterMajor: null,
      filterJurusan: null,
      filterSekolah: false,
      filterProvinsi: false,
      optRumpun: [],
      optMajor: [],
      optJurusan: null,
      // paketDataId: {
      //   name: null,
      // },
      form: {
        id: null,
        voucher_id: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "user.name", label: "Nama" },
        { key: "school.name", label: "Asal Sekolah" },
        { key: "majors[0].major.name", label: "Jurusan 1" },
        { key: "majors[0].nilai_akhir", label: "Nilai Akhir 1", sortable: true },
        { key: "majors[1].major.name", label: "Jurusan 2" },
        { key: "majors[1].nilai_akhir", label: "Nilai Akhir 2", sortable: true },
        // { key: "ranking.ke", label: "Ranking", sortable: true },
        // { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "text", value: "Voucher" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Voucher Paket",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Voucher Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Voucher Paket";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("snbp/createUpdateDeleteVoucher", [data])
            .then(() => {
              this.getRanking();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    async getRanking() {
      this.loading = true;
      const payload = {
        school_origin: this.filterSekolah != false ? this.user.detail.school_origin : null,
        provinsi: this.filterProvinsi != false && this.user.detail.school_origin_info ? this.user.detail.school_origin_info.provinsi : null,
        major_id: this.filterJurusan != null ? this.filterJurusan.value : null,
      };
      this.$store
        .dispatch("snbp/indexRanking", payload)
        .then((response) => {
          this.loading = false;
          let snbpranking = response.data.data;
          this.snbpranking = snbpranking;
          if (this.myRanking == null) {
            let myRanking = snbpranking.find((res) => {
              return res.user_id == this.user.id;
            });
            this.myRanking = myRanking;
          }
          if (this.optJurusan == null) {
            let optJurusan = [];
            this.snbpranking.forEach((el) => {
              el.majors.forEach((major) => {
                const existMajor = optJurusan.find((jurusan) => jurusan.value == major.major.id);
                if (!existMajor) {
                  optJurusan.push({
                    value: major ? major.major.id : "-",
                    text: major ? major.major.name : "-",
                  });
                }
              });
            });
            this.optJurusan = optJurusan;
          }
          this.totalRows = this.snbpranking.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    // async getDataById() {
    //   this.$store
    //     .dispatch("paketTryout/indexId", this.$route.params.id)
    //     .then((response) => {
    //       let paketDataId = response.data;
    //       this.paketDataId = paketDataId;
    //     })
    //     .catch((error) => {
    //       this.$root.$emit("errorData", error);
    //     });
    // },
  },
  created() {},
  mounted() {
    this.getRanking();
  },
};
</script>

<style>
/* .scroll {
  max-height: 100px;
  overflow-y: scroll;
} */
</style>
