<template>
  <div class="" id="section-to-print">
    <center>
      <b-card v-for="(item, i) in data.result" :key="i">
        <table class="table table-bordered">
          <tr>
            <th colspan="2" class="text-center bg-primary text-white">
              <b> Pilihan {{ item.pilihan_ke }} </b>
            </th>
          </tr>
          <tr>
            <td>Target Jurusan</td>
            <td>{{ item.major.name }}</td>
          </tr>
          <tr>
            <td>Passing Grade</td>
            <td>{{ item.major.passing_grade }}</td>
          </tr>
          <tr>
            <td>Daya Tampung</td>
            <td>{{ item.major.daya_tampung }}</td>
          </tr>
          <tr>
            <td>Peminat</td>
            <td>{{ item.major.peminat }}</td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td>Jumlah Alumni</td>
            <td>{{ item.sum_alumni }}</td>
          </tr>
          <tr>
            <td>Nilai Rata-Rata Raport</td>
            <td>{{ item.nilai_raport_average }}</td>
          </tr>
          <tr>
            <td>Nilai Mapel Pendukung</td>
            <td>{{ item.nilai_mapel_pendukung }}</td>
          </tr>
          <tr>
            <td>Nilai Piagam</td>
            <td>{{ item.nilai_piagam }}</td>
          </tr>
          <tr>
            <td>Nilai Awal</td>
            <td>{{ item.nilai_awal }}</td>
          </tr>
          <tr>
            <td>Nilai Akhir</td>
            <td>{{ item.nilai_awal }}</td>
          </tr>
          <tr>
            <td colspan="2"></td>
          </tr>
          <tr>
            <td><b> Hasil Kecocokan </b></td>
            <td>
              <b-badge :variant="hasilKecocokan(item.nilai_awal) == 1 ? 'light-success' : 'light-danger'" class="ml-1">{{ hasilKecocokan(item.nilai_awal) == 1 ? "Cocok" : "Tidak Cocok" }}</b-badge>
            </td>
          </tr>
          <tr>
            <td><b>Hasil Kelulusan</b></td>
            <td>
              <!-- <span v-if="item.sum_alumni > 0 ">
              <span></span>

            </span>
            <span v-else>
              
            </span> -->
              <b-badge :variant="hasilKelulusan(item.nilai_akhir, item.sum_alumni, item.major.passing_grade) == 1 ? 'light-success' : 'light-danger'" class="ml-1">{{
                hasilKelulusan(item.nilai_akhir, item.sum_alumni, item.major.passing_grade) == 1 ? "Lolos" : "Tidak Lolos"
              }}</b-badge>
            </td>
          </tr>
        </table>
      </b-card>
    </center>
    <b-button id="tombol-print" block @click="print">Print</b-button>
  </div>
</template>

<script>
import { BCard, BBadge, BButton } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BBadge,
    BButton,
  },
  props: ["data"],
  methods: {
    hasilKecocokan(nilai_awal) {
      if (nilai_awal <= 84) {
        return 0;
      } else {
        return 1;
      }
    },
    hasilKelulusan(nilai_akhir, sum_alumni, passing_grade) {
      if (sum_alumni > 0) {
        let nilai = nilai_akhir + 5;
        if (nilai >= passing_grade) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (nilai_akhir >= passing_grade) {
          return 1;
        } else {
          return 0;
        }
      }
    },
    print() {
      window.print();
    },
  },
};
</script>

<style>
@media print {
  body {
    visibility: hidden;
  }
  #tombol-print {
    visibility: hidden;
  }
  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
